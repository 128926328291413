// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAqy7bIA1s1OOdq6UPEEiLzcpiC0Ii7lgs",
  authDomain: "yvomnew3rd.firebaseapp.com",
  databaseURL: "https://yvomnew3rd-default-rtdb.firebaseio.com",
  projectId: "yvomnew3rd",
  storageBucket: "yvomnew3rd.appspot.com",
  messagingSenderId: "39135732112",
  appId: "1:39135732112:web:86745b382f4a22c6291315",
  measurementId: "G-1H5BJQLY3J"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };